import { jsxs as r, jsx as t } from "react/jsx-runtime";
import { classnames as $, toFriendly as f } from "@vgno/utils";
import './assets/VideoList.css';const z = "_root_zga24_1", y = "_link_zga24_10", b = "_header_zga24_25", x = "_item_zga24_40", V = "_first_zga24_58", j = "_image_zga24_99", D = "_list_zga24_105", T = "_labels_zga24_112", q = "_title_zga24_122", a = {
  root: z,
  link: y,
  header: b,
  item: x,
  first: V,
  image: j,
  list: D,
  labels: T,
  title: q
}, g = ({ epoch: i }) => i ? /* @__PURE__ */ t("time", { children: ((s) => f(s * 1e3))(i) }) : null, C = ({
  className: i,
  data: o,
  label: s = "timestamp",
  title: p,
  vgtvUrl: d = "https://tv.vg.no",
  ...u
}) => {
  var h;
  const [e, ...k] = o, m = "?t[]=480q80";
  return /* @__PURE__ */ r("div", { className: $(a.root, i), ...u, children: [
    /* @__PURE__ */ t("h3", { className: a.header, children: p }),
    /* @__PURE__ */ r(
      "a",
      {
        className: a.first,
        "data-track-id": `teaser:${e.id}`,
        "data-track-name": e.title,
        href: e.additional.url ? `https://${e.additional.url}` : `https://tv.vg.no/video/${e.id}`,
        children: [
          /* @__PURE__ */ r("div", { className: a.labels, children: [
            /* @__PURE__ */ t("h4", { className: a.title, children: e.title }),
            s === "timestamp" && /* @__PURE__ */ t(g, { epoch: ((h = e.flightTimes) == null ? void 0 : h.start) || void 0 }),
            s === "category" && /* @__PURE__ */ t("span", { children: e.category.title })
          ] }),
          /* @__PURE__ */ t("picture", { children: /* @__PURE__ */ t(
            "img",
            {
              alt: "",
              className: a.image,
              src: `${e.images.main}${m}`
            }
          ) })
        ]
      }
    ),
    /* @__PURE__ */ r("ul", { className: a.list, children: [
      k.map(
        ({ category: v, flightTimes: n, id: c, images: N, title: _, additional: l }) => /* @__PURE__ */ t("li", { children: /* @__PURE__ */ r(
          "a",
          {
            className: a.item,
            "data-track-id": `teaser:${c}`,
            "data-track-name": _,
            "data-track-target": l.url ? `https://${l.url}` : `https://tv.vg.no/video/${c}`,
            href: l.url ? `https://${l.url}` : `https://tv.vg.no/video/${c}`,
            children: [
              /* @__PURE__ */ t(
                "img",
                {
                  alt: "",
                  className: a.image,
                  src: `${N.main}${m}`
                }
              ),
              /* @__PURE__ */ r("div", { className: a.labels, children: [
                /* @__PURE__ */ t("h4", { className: a.title, children: _ }),
                s === "timestamp" && /* @__PURE__ */ t(g, { epoch: (n == null ? void 0 : n.start) || void 0 }),
                s === "category" && /* @__PURE__ */ t("span", { children: v.title })
              ] })
            ]
          }
        ) }, c)
      ),
      /* @__PURE__ */ t("li", { children: /* @__PURE__ */ t(
        "a",
        {
          className: a.link,
          "data-track-id": "upcoming-broadcasts-cta",
          "data-track-name": "Upcoming Broadcasts CTA",
          "data-track-target": d,
          href: d,
          children: "Se mer på VGTV"
        }
      ) })
    ] })
  ] });
};
export {
  C as VideoList,
  C as default
};
